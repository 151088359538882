import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CalendarComponentOptions } from 'ion2-calendar';
import moment from 'moment';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.page.html',
  styleUrls: ['./date-picker-modal.page.scss'],
})
export class DatePickerModalPage implements OnInit {
  public minDate = moment().add(1, 'd');
  public maxDate = moment().add(1, 'd');

  selectedDate: string;
  type: 'string';

  optionsRange: CalendarComponentOptions = {
    pickMode: 'single',
    color: 'secondary',
    // from: new Date(2001, 1, 1),
    // to: new Date(),
  };

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {}

  ngOnInit() {
    this.selectedDate = '';
    if (this.params.get('minDate')) {
      this.minDate = moment(new Date(this.params.get('minDate')));
      this.minDate = this.minDate.add(1, 'days');
    }

    if (this.params.get('maxDate')) {
      this.maxDate = this.params.get('maxDate');
    }

    const daysConfig = [];

    if (this.params.get('data') && this.params.get('data').availabilityjson) {
      const unAvailableDates = JSON.parse(
        this.params.get('data').availabilityjson,
      );
      unAvailableDates.map(unavailableDate => {
        daysConfig.push({
          date: moment(new Date(unavailableDate)).format('YYYY-MM-DD'),
          marked: false,
          disable: true,
        });
      });
    }

    let checkDate = moment(new Date());
    for (let i = 0; i <= 650; i++) {
      if (checkDate.toDate().getTime() < this.minDate.toDate().getTime()) {
        daysConfig.push({
          date: checkDate.format('YYYY-MM-DD'),
          marked: false,
          disable: true,
        });
      } else {
        daysConfig.push({
          date: checkDate.format('YYYY-MM-DD'),
          marked: false,
          disable: false,
        });
      }

      checkDate = checkDate.add(1, 'days');
    }
    if (this.params.get('expDates')) {
      this.optionsRange.from = new Date(2001, 1, 1);
      this.optionsRange.to = new Date();
    }

    this.optionsRange.daysConfig = daysConfig;
  }

  async onChange($event) {
    this.selectedDate = $event.format('YYYY-MM-DD');
    await this.modalController.dismiss({ date: this.selectedDate });
  }

  async back() {
    await this.modalController.dismiss({ date: '' });
  }
}
