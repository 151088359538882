import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/models/user.model';

@Component({
  selector: 'app-specify-hunters',
  templateUrl: './specify-hunters.page.html',
  styleUrls: ['./specify-hunters.page.scss'],
})
export class SpecifyHuntersPage implements OnInit {
  selectedHunters: Array<User>;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {
    this.selectedHunters = new Array<User>();
  }

  ngOnInit() {
    this.selectedHunters = this.params.get('selectedHunters');
  }

  addContact() {
    var that = this;
    //@ts-ignore
    window.ContactsX.hasPermission(
      function (result) {
        if (result.read) {
          //@ts-ignore
          window.ContactsX.pick(
            function (contact) {
              that.selectedHunters.push({
                firstname: contact.firstName,
                lastname: contact.familyName,
                mobile: contact.phoneNumbers[0].value,
              });
            },
            function (error) {
              alert('Unable to select contact: ' + JSON.stringify(error));
            },
          );
        } else {
          //@ts-ignore
          window.ContactsX.requestPermission(
            function (result) {
              if (result.read) {
                setTimeout(() => {
                  that.addContact();
                }, 500);
              }
            },
            function (error) {
              alert(
                'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
              );
            },
          );
        }
      },
      function (error) {},
    );
  }

  // addContact() {
  //   var that = this;

  //   try {
  //     const contacts = navigator['contacts'];
  //     if (contacts) {
  //       contacts.pickContact(
  //         function (contact) {
  //           that.selectedHunters.push({
  //             firstname: contact.name.givenName,
  //             lastname: contact.name.familyName,
  //             mobile: contact.phoneNumbers[0].value,
  //           });
  //           // alert(
  //           //   'The following contact has been selected:' +
  //           //     JSON.stringify(contact.name.givenName),
  //           // );
  //         },
  //         function (err) {
  //           if (JSON.stringify(err).indexOf('20') !== -1) {
  //             alert(
  //               'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
  //             );
  //           }
  //         },
  //       );
  //     }
  //   } catch (ex) {
  //     alert(
  //       'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
  //     );
  //   }
  // }

  async back() {
    await this.modalController.dismiss(this.selectedHunters);
  }

  delete(hunter) {
    let newHunters = this.selectedHunters.filter(item => {
      return hunter != item;
    });
    this.selectedHunters = newHunters;
  }

  specifyHunter(hunter) {
    const that = this;

    //@ts-ignore
    window.ContactsX.hasPermission(
      function (result) {
        if (result.read) {
          //@ts-ignore
          window.ContactsX.pick(
            function (contact) {
              that.selectedHunters.shift();
              that.selectedHunters.push({
                firstname: contact.firstName,
                lastname: contact.familyName,
                mobile: contact.phoneNumbers[0].value,
              });
            },
            function (error) {
              alert('Unable to select contact: ' + JSON.stringify(error));
            },
          );
        } else {
          //@ts-ignore
          window.ContactsX.requestPermission(
            function (result) {
              if (result.read) {
                setTimeout(() => {
                  that.addContact();
                }, 500);
              }
            },
            function (error) {
              alert(
                'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
              );
            },
          );
        }
      },
      function (error) {},
    );
  }
}
