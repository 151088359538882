import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppGlobals } from 'src/app/app.globals';

@Component({
  selector: 'app-user-count',
  templateUrl: './user-count.page.html',
  styleUrls: ['./user-count.page.scss'],
})
export class UserCountPage implements OnInit {
  farmerCount: number;
  userCount: number;
  businessCount: number;
  version: string = AppGlobals.VERSION;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {}

  ngOnInit() {
    if (this.params.get('farmerCount')) {
      this.farmerCount = this.params.get('farmerCount') * 1;
      this.userCount = this.params.get('userCount') * 1;
      this.businessCount = this.params.get('businessCount') * 1;
    }
  }

  async back() {
    await this.modalController.dismiss({ date: '' });
  }
}
